import React, { useState, useEffect } from 'react'
import { Grid, GridItem } from '@chakra-ui/react'
import { Link } from './link'
import { Image } from './image'
import { getInstagram, InstagramMediaData } from '../../utils/get-instagram'

export function InstagramGallary() {
  const [gallery, setGallery] = useState<InstagramMediaData[]>([])

  useEffect(() => {
    getInstagram('c_daidougei0405').then(result => {
      setGallery(result ?? [])
    })
  }, [])

  return (
    <Grid
      templateColumns={{
        base: 'repeat(auto-fill, 100px)',
        sm: 'repeat(auto-fill, 200px)',
        md: 'repeat(auto-fill, 260px)',
      }}
      justifyContent={'center'}
      gap={8}
    >
      {gallery.map(media => (
        <GridItem key={media.id}>
          <Link href={media.permalink}>
            <Image
              src={media.media_url}
              boxSize={{
                base: 50,
                sm: 100,
                md: 130,
              }}
              objectFit={'cover'}
            />
          </Link>
        </GridItem>
      ))}
    </Grid>
  )
}
