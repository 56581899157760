import { setup } from 'axios-cache-adapter'

export interface InstagramMediaData {
  id: string
  media_url: string
  permalink: string
  caption: string
  timestamp: string
}

const client = setup({
  cache: {
    readHeaders: true,
  },
})

export async function getInstagram(
  username?: string
): Promise<InstagramMediaData[]> {
  const query =
    'media.limit(8){id,media_url,caption,like_count,permalink,username}'

  try {
    const { data: result, status } = await client.get(
      `/api/get-instagram-gallery.php`,
      {
        params: {
          fields: username
            ? `business_discovery.username(${username}){${query}}`
            : query,
        },
      }
    )

    if (username) {
      return result.business_discovery?.media?.data ?? []
    } else {
      return result.media?.data ?? []
    }
  } catch (e) {
    console.log(e)
  }
}
