import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Hero } from '../components/site-only/hero'
import { SlideFadeInView } from '../components/slide-fade-in-view'
import { Button, Center, Container, useToken } from '@chakra-ui/react'
import { Section, SectionContent, SectionHeading } from '../components/section'
import {
  HeadingGroup,
  HeadingSubtitle,
  HeadingTitle,
} from '../components/heading-group'
import { Link } from '../components/site-only/link'
import { Icon } from '../components/icon'

// @ts-ignore
import HeroImage from '../images/hero_01.jpg'
import { InstagramGallary } from '../components/site-only/instagram-gallary'
import { Wrapper } from '../components/site-only/wrapper'

export default function Report({
  data: { report },
}: PageProps<GatsbyTypes.ReportQuery>) {
  const section = report?.section

  return (
    <>
      {section && (
        <>
          <Hero
            bgImage={{
              base: `url(${HeroImage})`,
              md: `url(${HeroImage})`,
            }}
            bgPos={{
              base: 'right -26vw bottom 2vw',
              md: 'right bottom',
            }}
            bgSize={{
              base: '110% auto',
              md: '70% auto',
            }}
          >
            <Container>
              <SlideFadeInView>
                <Section>
                  <SectionHeading
                    as={HeadingGroup}
                    variant={'white'}
                    textAlign={{
                      base: 'center',
                      md: 'unset',
                    }}
                  >
                    <HeadingTitle>{section.heading?.value}</HeadingTitle>
                    <HeadingSubtitle>
                      {section.subtitle?.element?.value}
                    </HeadingSubtitle>
                  </SectionHeading>
                </Section>
              </SlideFadeInView>
            </Container>
          </Hero>

          <Wrapper invert layerStyle={'light'}>
            <Container>
              <SlideFadeInView>
                <Section as={'div'}>
                  <SectionContent as={'div'}>
                    <InstagramGallary />
                  </SectionContent>
                  <SectionContent as={Center}>
                    <Button
                      as={Link}
                      variant={'rounded.outline'}
                      size={'md'}
                      {...section.contents[0]?.link?.properties}
                    >
                      <Icon kind={'instagram'} mr={5} />
                      {section.contents[0]?.link?.value}
                    </Button>
                  </SectionContent>
                </Section>
              </SlideFadeInView>
            </Container>
          </Wrapper>
        </>
      )}
    </>
  )
}

export const query = graphql`
  query Report {
    report: markdownRemark(frontmatter: { title: { eq: "Report" } }) {
      section: childElement(filter: { type: { eq: "section" } }) {
        key: id
        ...MarkdownHeading
        ...MarkdownSubtitle
        contents: childrenElement(
          filter: {
            type: { eq: "paragraph" }
            children: { type: { nin: ["strong"] } }
          }
        ) {
          key: id
          tagName
          value
          ...MarkdownLink
        }
      }
    }
  }
`
